

.nav-link {
    display: block;
    border: 1px solid transparent;
    padding: .5rem 1rem;
}

.active {
    background-color: #ffffff;
}